// const baseURL = "http://localhost:1337/api";
// const baseURL = "http://134.122.90.137:1340/api";
// const baseURL = "http://137.184.195.58:1337/api";
const baseURL = "https://clever.everestminds.com/api";

export const END_POINTS = {
  login: baseURL + "/gaurdLogin",
  getGolfCarGaurds: baseURL + "/allGolfCarGaurds",
  setGolfCarGaurd: baseURL + "/setGolfCarGaurd",
  getAvailableSlots: baseURL + "/getAvailableSlots",
  updateAvailableSlots: baseURL + "/updateAvailableSlots",
};
