import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
// import { Image } from "mui-image";
import React, { useState, useEffect } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import Copyright from "../components/Copyright";
import { END_POINTS } from "../END_POINTS";
import logo from "../assets/CarIcon.png";
import Swal from "sweetalert2";

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Login() {
  const [userName, setUserName] = useState("");
  const [userPasswrod, setUserPasswrod] = useState("");
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [userpasswordTouched, setUserpasswordTouched] = useState(false);
  const [errors, SetErrors] = useState("");

  useEffect(() => {
    if (userName !== "" && userPasswrod !== "") {
      SetErrors("");
    }
  }, [userName, userPasswrod]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log({
    //   name: userName,
    //   password: userPasswrod,
    // });

    try {
      if (userName === "" || userPasswrod === "") {
        SetErrors("برجاء إكمال كافة بيانات تسجيل الدخول المطلوبة");
        return;
      }
      const loginResult = await axios.post(END_POINTS.login, {
        name: userName,
        password: userPasswrod,
      });

      console.log(
        "🚀 -> file: Login.jsx:48 -> handleSubmit -> loginResult",
        loginResult
      );

      if (loginResult?.data?.data.id !== undefined) {
        localStorage.setItem("user", JSON.stringify(loginResult.data.data));
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "بيانات تسجيل الدخول خاطئة!!",
        confirmButtonText: "حسنًا",
      });
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <img width={"42px"} height={"42px"} src={logo} alt="Logo" />
            <Typography sx={{ fontSize: "25pt", mt: "32px" }}>
              مرحبا بك !
            </Typography>
            <Typography sx={{ fontSize: "16pt", color: "#818181" }}>
              يرجي تسجيل الدخول
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="إسم المستخدم"
                name="email"
                autoFocus
                error={usernameTouched && userName === ""}
                helperText={
                  usernameTouched && userName === ""
                    ? "من فضلك أدخل إسم المستخدم"
                    : ""
                }
                onChange={(e) => {
                  setUsernameTouched(true);
                  setUserName(e.target.value);
                }}
                sx={{
                  "& label": { fontWeight: "bold", fontSize: 20 },
                  "& fieldset": { borderRadius: "8pt" },
                }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="كلمة السر"
                type="password"
                id="password"
                error={userpasswordTouched && userPasswrod === ""}
                helperText={
                  userpasswordTouched && userPasswrod === ""
                    ? "من فضلك أدخل كلمة السر"
                    : ""
                }
                onChange={(e) => {
                  setUserpasswordTouched(true);
                  setUserPasswrod(e.target.value);
                }}
                sx={{
                  "& label": { fontWeight: "bold", fontSize: 20 },
                  "& fieldset": { borderRadius: "8pt" },
                }}
              />
              {errors !== "" && (
                <Typography sx={{ fontSize: "14pt", color: "red" }}>
                  {errors}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  height: "52px",
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#0078C1",
                  fontSize: 21,
                  borderRadius: "8pt",
                }}
              >
                تسجيل الدخول
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
