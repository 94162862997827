import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import Swal from "sweetalert2";
import logo from "../assets/CarIcon.png";
import { END_POINTS } from "../END_POINTS";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.roche.com/">
        Roche
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export default function Home({ user }) {
  const [loading, setLoading] = useState(false);
  const [parkingLots, setParkingLots] = useState(0);
  const [notes, setNotes] = useState("");
  const [parkingLotsTouched, setParkingLotsTouched] = useState(false);
  const [golfCarGaurds, setGolfCarGaurds] = useState([]);
  const [selectedCarGuy, setSelectedCarGuy] = useState(0);
  const [latestPerson, setLatestPerson] = useState("");
  const [totalAvaliableSlots, setTotalAvaliableSlots] = useState(0);
  const getGolfCarGaurds = async () => {
    setLoading(true);
    let res = await axios.get(END_POINTS.getGolfCarGaurds);
    setGolfCarGaurds(res.data.data);
    const onDutyCarGuy = res.data.data.find(
      (carGuy) => carGuy.is_onDuty === true
    );
    setSelectedCarGuy(onDutyCarGuy.id);
  };

  const getParkingLots = async () => {
    const res = await axios.get(END_POINTS.getAvailableSlots);
    console.log(
      "🚀 -> file: Home.jsx:67 -> getParkingLots -> res:",
      res?.data?.data
    );
    if (res?.data?.data?.available_slots !== undefined) {
      setParkingLots(res?.data?.data?.available_slots);
      setTotalAvaliableSlots(res?.data?.data?.total_slots);
      setNotes(res?.data?.data?.notes);
      if (res?.data?.data?.updated_by_parking_guy === true) {
        setLatestPerson(res?.data?.data?.security_gaurd?.name);
      } else {
        setLatestPerson(res?.data?.data?.roche_user?.username);
      }
    }
    setLoading(false);
  };

  const LOGOUT = () => {
    Swal.fire({
      title: "تسجيل الخروج",
      text: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "لا",
      confirmButtonText: "نعم",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    getGolfCarGaurds();
    getParkingLots();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (parkingLots === "") {
      alert("برجاء إدخال عدد أماكن فارغة صحيح");
      return;
    }
    await axios.post(END_POINTS.setGolfCarGaurd, {
      golfGaurdId: selectedCarGuy,
    });
    let stringifiedCurrentSecurityGaurd = localStorage.getItem("user");
    let CurrentSecurityGaurd = JSON.parse(stringifiedCurrentSecurityGaurd);

    await axios.post(END_POINTS.updateAvailableSlots, {
      available_slots: parkingLots,
      notes,
      security_gaurd: CurrentSecurityGaurd.id,
    });

    window.location.reload();
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {loading && <CircularProgress />}
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <img width={"42px"} height={"42px"} src={logo} alt="Logo" />

              <Button
                sx={{
                  color: "#d63031",
                  marginLeft: "45px !important",
                  fontSize: "15pt",

                  mt: "32px",
                  fontWeight: "bold",
                }}
                color="error"
                variant="outlined"
                onClick={LOGOUT}
              >
                تسجيل الخروج
              </Button>
            </Box>
            <Typography sx={{ fontSize: "25pt", mt: "32px" }}>
              أهلًا ، {user.name}
            </Typography>
            <Typography sx={{ fontSize: "16pt", color: "#818181" }}>
              يرجي التحديث المستمر لبيانات موقف السيارات
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <Typography
                sx={{
                  "& label": {
                    fontWeight: "bold",
                    color: "#fe43",
                    fontSize: 20,
                  },
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                مسؤول سيارة الجولف
              </Typography>

              <Select
                fullWidth
                id="demo-simple-select"
                value={selectedCarGuy}
                onChange={(e) => {
                  setSelectedCarGuy(e.target.value);
                }}
                sx={{ mt: 1, mb: 1, "& fieldset": { borderRadius: "8pt" } }}
              >
                {golfCarGaurds.length === 0 && (
                  <MenuItem key={0} value={0}>
                    لا يوجد مسؤولين متاحين الآن
                  </MenuItem>
                )}
                {golfCarGaurds.length > 0 &&
                  golfCarGaurds.map((carGuy) => {
                    return (
                      <MenuItem key={carGuy.id} value={carGuy.id}>
                        {carGuy.name}
                      </MenuItem>
                    );
                  })}
              </Select>

              <Typography
                sx={{
                  "& label": {
                    fontWeight: "bold",
                    color: "#fe43",
                    fontSize: 20,
                  },
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                ملاحظات
              </Typography>

              <TextField
                margin="normal"
                fullWidth
                multiline
                name="notes"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                sx={{
                  "& label": { fontWeight: "bold", fontSize: 20 },
                  "& fieldset": { borderRadius: "8pt" },
                }}
              />

              <Typography
                sx={{
                  "& label": {
                    fontWeight: "bold",
                    color: "#fe43",
                    fontSize: 20,
                  },
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                عدد الأماكن الفارغة من ( {totalAvaliableSlots} مكان )
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "space-between",
                  alignItems: "center",
                  marginTop: "-12px",
                  gap: "5px",
                }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  type="number"
                  id="password"
                  error={
                    (parkingLotsTouched && parkingLots < 0) ||
                    (parkingLotsTouched && parkingLots === "")
                  }
                  helperText={
                    (parkingLotsTouched && parkingLots < 0) ||
                    (parkingLotsTouched && parkingLots === "")
                      ? `عدد الأماكن المتاحة يجب أن يكون أقل من أو يساوي ${totalAvaliableSlots} `
                      : ""
                  }
                  value={parkingLots}
                  onChange={(e) => {
                    let int = parseInt(e.target.value);
                    if (int >= 0 && int <= totalAvaliableSlots) {
                      setParkingLots(e.target.value);
                      setParkingLotsTouched(true);
                    } else if (e.target.value === "") {
                      setParkingLotsTouched(true);
                      setParkingLots("");
                    }
                  }}
                  sx={{
                    "& label": { fontWeight: "bold", fontSize: 20 },
                    "& fieldset": { borderRadius: "8pt" },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    height: "52px",
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#0078C1",
                    fontSize: 21,
                    borderRadius: "8pt",
                  }}
                >
                  تحديث البيانات
                </Button>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Typography sx={{ fontSize: "16pt", color: "#818181" }}>
                  آخر تعديل تم بواسطة [ {latestPerson} ]
                </Typography>
              </Box>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </CacheProvider>
  );
}
