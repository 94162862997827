import React, { useState, useEffect } from "react";
import Login from "./pages/Login";
import Home from "./pages/Home";

function App() {
  const [user, setUser] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("user");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
    // return "محمد";
  });

  return (
    <>
      {user !== "" && <Home user={user} />}
      {user === "" && <Login />}
    </>
  );
}

export default App;
